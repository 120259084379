import React from "react";
import SubPageBase from "../components/SubPageBase";
import img from "../images/department.png";

const Index = () => (
  <SubPageBase title="Aday Aydınlatma Metni" img={img}>
    <h2>6698 SAYILI KİŞİSEL VERİLERİN KORUNMASI KANUNU KAPSAMINDA ADAY AYDINLATMA METNİ</h2>
    <h3>Veri Sorumlusu</h3>
    <b>TURKSPORT SPOR ÜRÜNLERİ SAN. VE TİC. LTD. ŞTİ.</b> (Bundan sonra <b>“Decathlon”</b> olarak anılacaktır.) olarak
    özel hayatın gizliliğine saygı duyuyor ve önem veriyoruz. Bu nedenle, sizleri 6698 sayılı Kişisel Verilerin
    Korunması Kanunu (Bundan sonra <b>“KVKK”</b> olarak anılacaktır.) kapsamında, veri sorumlusu sıfatı ile işlediğimiz
    kişisel verilerinizin kullanımı ve korunması ile ilgili haklarınız konusunda bilgilendirmek istiyoruz.
    <h3>İşlenen Kişisel Veriler</h3>
    Şirketimiz tarafından yasal mevzuat ile şirket politika ve prosedürleri gereği KVKK ve ilgili sair mevzuata uygun
    olarak işbu Aday Aydınlatma Metni’nde belirtilen amaçlar ve hukuki sebepler kapsamında işlenecek kişisel veri
    kategori ve açıklamaları aşağıdaki gibidir: Şirketimiz bünyesinde başvurduğunuz pozisyon ve/veya başvurduğunuz
    pozisyona kabul edilmemeniz halinde ileride oluşabilecek pozisyon/pozisyonlar için değerlendirilmeniz ve sizinle
    tekrar iletişime geçilebilmesi; başvurduğunuz pozisyon için kabul edilmeniz halinde iş sözleşmenizin kurulması ve
    ifası için;
    <br />
    <br />
    <b>Kimlik Bilgileri: </b>
    Adı-soyadı, cinsiyet, doğum tarihi, medeni durum
    <br />
    <b>İletişim Bilgileri: </b>
    Telefonu numarası, e-posta adres, adres bilgisi (çalışanlarımıza sağlamakta olduğumuz shuttle hakkından yararlanıp
    yararlanmayacağınızın tespiti ve mağaza başvuruları bakımından ise ikamet edilen adrese en yakın mağazanın tespit
    edilip değerlendirme yapılabilmesi bakımından yaşadığınız ülke, il, ilçe ile sınırlı olarak adresiniz)
    <br />
    <b>Eğitim Bilgileri: </b>
    Eğitim seviyesi, Tecrübe yılı, Eğitim geçmişi, İş tecrübesi geçmişi, yabancı dil bilgisi, bilgisayar bilgisi,
    sertifikalar
    <br />
    <b>Özlük Bilgileri: </b>
    Önceki iş deneyimleriniz (şirket adı, yıllar, son unvanınız, en son çalıştığınız şirketiniz, son pozisyon
    seviyeniz), askerlik durumunuz, tecil tarihiniz), ön yazı ve motivasyon mektuplarınız, başvurulan pozisyona yönelik
    mülakat sorularımıza cevaplarınız
    <br />
    <b>Görsel ve İşitsel Kayıtlar: </b>
    Profil fotoğrafı, video kayıtları
    <br />
    <b>Özel Nitelikli Kişisel Veriler: </b>
    Özgeçmişte yer vermeniz halinde dernek, vakıf, sendika, siyasi parti üyeliklerine ait bilgiler, dini veya felsefi
    görüşünüze, etnik kökeninize ve ırkınıza ait veya o konuda kanaat oluşturabilecek bilgiler, Özgeçmişinizde
    belirtmeniz halinde, sağlık durumunuzu ve/veya engellilik durumunuza dair bilgiler, Özgeçmişinizde manuel doldurulan
    alanlarda belirtebileceğiniz özel nitelikli kişisel verileriniz.
    <br />
    <b>İşlem Güvenliği: </b>
    IP adresi, log kayıtları
    <br />
    <b>Pazarlama: </b>
    Çerezler
    <br />
    <b>Diğer: </b>
    Spor yapma durumu ve tercihi bilgisi, Referans bilgileri (referans kişisinin adı-soyadı, çalıştığı firma, telefonu,
    e-postası, unvan, yakınlık) ve varsa özgeçmişiniz içerisinde eklediğiniz kişisel ve yönetsel becerilerinize dair
    kanaat oluşturabilecek ön yazı, sertifika, hobi ve yetkinlikler, sürücü belgesi bilgileri, askerlik durumu, maaş
    beklentisi
    <br />
    <br />
    Kişisel verilerinizden Ad soyad, E-mail adresi, Telefon, Cinsiyet, Doğum tarihi, Eğitim seviyesi, Tecrübe yılı,
    Sporları, Eğitim geçmişi, Tecrübe geçmişi, Profil resmi internet sitemizin kariyer bölümünde yer alan açık
    pozisyonlara başvurabilmeniz için hesap oluşturmanız amacıyla da işlenecektir. Şirketimiz tarafından talep
    edilmemesine rağmen özgeçmişinize eklemeniz veya online kariyer sitemize yüklemeniz halinde Şirketimize
    aktaracağınız diğer kişisel verileriniz ve özel nitelikli kişisel verileriniz (dernek üyeliğiniz, sağlık
    bilgileriniz gibi) işlenecektir Başvuru formunda ve talep edilen bilgiler haricindeki bilgiler başvuru sürecimizde
    talep edilmemektedir. Ancak talep edilmese dahi paylaşacağınız kişisel verileriniz işlenecektir. Bu kapsamda,
    sitemizde işlenmesini tercih etmediğiniz kişisel verileri, manuel olarak doldurulan alanlara girmemenizi öneririz.
    Engelli adayı pozisyonu için değerlendirilmeniz veya İŞKUR tarafından bu amaçla istihdam edilmek için
    yönlendirilmeniz halinde engelli olup olmadığınız, engel durumu ve engellilik derecenize (yüzde oranı) ilişkin
    bilgileriniz işlenecektir. Sizi uygun pozisyonlardan yararlandırabilmek adına, bir fonksiyon olarak sunduğumuz
    hesabınıza dosya yükleme özelliğini kullanarak yüklediğiniz dosyalarda bulunan bilgileri de işlemekteyiz.
    Şirketimize aktaracağınız referanslarınız ve aile bireylerinize ait kişisel verilerle ilgili olarak söz konusu
    kişilerin bilgilendirilmeleri ve gerektiği taktirde açık rızalarının alınmasına ilişkin sorumluluk tarafınıza
    aittir.
    <h3>Kişisel Verilerin Hangi Amaçlarla İşleneceği</h3>
    Kişisel verileriniz,
    <ul>
      <li>- Çalışan/Stajyer adayı seçme ve yerleştirme süreçlerinin yönetilmesi,</li>
      <li>- Çalışan/Stajyer adaylarının başvuru süreçlerinin yürütülmesi,</li>
      <li>- İnsan kaynakları süreçlerinin planlanması,</li>
      <li>- Decathlon Kariyer portalına üye bilgileriniz ile giriş yapabilmeniz,</li>
      <li>- İletişim faaliyetlerinin yürütülmesi,</li>
      <li>- Hukuk işlerinin takibi ve yürütülmesi,</li>
    </ul>
    amaçlarıyla KVKK’nın 5 (2) (c) maddesinde belirtilen “c) Bir sözleşmenin kurulması veya ifasıyla doğrudan doğruya
    ilgili olması kaydıyla, sözleşmenin taraflarına ait kişisel verilerin işlenmesinin gerekli olması” hukuki sebebi
    dâhilinde işlenecektir.
    <br />
    Özgeçmişiniz içerisinde ilettiğiniz özel nitelikli kişisel verileriniz, bu maddede belirtilen amaçlarla KVKK’nın 6
    (2) maddesinde belirtilen “özel nitelikli kişisel verilerin, ilgilinin kişinin açık rızası olmaksızın işlenmesi
    yasaktır.” hukuki sebebi dâhilinde işlenecektir.
    <h3>Kişisel Verilerin Toplama Yöntemi ve Hukuki Sebebi</h3>
    <p>
      Kişisel Verileriniz; Şirketimizin üye olduğu online kariyer sitelerinde ve/veya internet sitemizin kariyer
      bölümünde açılan pozisyonlara başvurmanız, anılan web siteleri üzerinden işe alım yetkililerimize gönderdiğiniz
      mesajlar/özgeçmişler, Şirket çalışanlarımıza e-posta yoluyla gönderdiğiniz özgeçmişiniz, danışmanlık firmaları
      veya İŞKUR tarafından Şirketimizle paylaşılan bilgileriniz; iş başvuru formu aracılığıyla, iş görüşmesi esnasında,
      işe alım sürecinde aday değerlendirmelerinden oluşan raporlar, görüşme notları, kişilik ve karakter testleri ile
      analiz sonuçları, yabancı dil ve yetenek gibi ilgili sınav sonuçları ile tarafınızdan elde edilen bilgileriniz,
      sağladığınız referanslarınız veya eski işverenleriniz aracılığıyla veya bilgi iletişim araçları vasıtasıyla
      otomatik olan ve/veya otomatik olmayan yollarla Kanun’a uygun şekilde elde edilmektedir.
    </p>
    <p>
      Yukarıda belirtilen yöntemlerle iş başvurunuzun değerlendirilmesi için toplanılan Kişisel Verileriniz, Kanunun 5
      inci maddesinde belirtilen iş sözleşmesinin kurulması, açık rızanız ve Şirketimizi ziyaret etmeniz halinde
      güvenlik amacıyla toplanan kişisel verileriniz ile internet sitemizin kariyer bölümünde kullanıcı hesabı
      oluşturmak için toplanan kişisel verileriniz ise meşru menfaat hukuki sebebine dayanarak işlenmektedir.
    </p>
    <h3>İşlenen Kişisel Verilerin Kimlere ve Hangi Amaçla Aktarılabileceği</h3>
    <p>
      Elde edilen Kişisel Verileriniz, Kişisel Verileri Koruma Kurulu tarafından öngörülen temel ilkelere uygun olarak
      ve Kanun’un 8. ve 9. maddelerinde belirtilen kişisel veri işleme şartları ve amaçları dahilinde;
      <ul>
        <li>
          - Şirket’in IK süreçleri ilgili destek hizmetlerin alınabilmesi amacıyla hizmet alınan firmalara ve
          tedarikçilere veya
        </li>
        <li>- Şirket’in yasal yükümlülüklerini yerine getirilebilmesi için yetkilendirilmiş kurum ve kuruluşlara,</li>
        <li>
          - Şirket’in bilgi teknolojileri, hosting ve bulut bilişim hizmetleri alması, kapsamında veya veri saklama ve
          güvenliği kararları doğrultusunda; yurtiçinde ve kişisel verilerin korunması hususunda yeterli koruma bulunan
          ülkeler (Kurul tarafından ilan edilecektir) ve/veya yeterli koruma bulunmayan ülkeler için Kanun’da belirtilen
          şartlara uyulması kaydıyla ve sadece gerektiğinde Türkiye dışında bulunan bilgi teknolojileri, hosting ve
          bulut bilişim firmalarına aktarılabilecektir.
        </li>
      </ul>
    </p>
    <h3>KVKK’nın 11. Maddesi Kapsamındaki Haklarınız</h3>
    <p>
      KVKK’nın 11. maddesi kapsamında, veri sahibi olarak haklarınız bulunmakta olup bu haklarınız ile ilgili
      taleplerinizi,{" "}
      <b>
        <a
          href="https://kesfet.decathlon.com.tr/cdn/decathlon_kvkk_basvuru_2020.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          İlgili Kişi Başvuru Formu
        </a>
      </b>
      ’nda belirtilen bütün bilgileri doldurmak suretiyle KVKK’nın 11. maddesi ile 13. maddesinin 1. fıkrası ve{" "}
      <b>Veri Sorumlusuna Başvuru Usul ve Esasları Hakkında Tebliğ</b> gereğince,
      <ul>
        <li>
          - “Osmaniye Mahallesi Çobançeşme Koşuyolu Bulvarı Marmara Forum Si. A Bl. Ap. N.3/Z099 Bakırköy İstanbul”
          adresindeki Şirketimize bizzat gelerek yazılı olarak teslim etmek sureti ile, kimliğinizi tespit edebilmek ve
          yanlış kişilere bilgi vermemek adına yazılı olarak noter aracılığı ile veya iadeli taahhütlü mektup ile,
        </li>
        <li>
          - güvenli elektronik imza, mobil imza ya da (varsa) tarafınızca daha Şirketimize bildirilen ve sistemlerimizde
          kayıtlı bulunan elektronik posta adresini kullanmak suretiyle kisiselverilerim@decathlon.com adresine
          elektronik posta göndererek
        </li>
        <li>- veya gelecekte Kurul’un belirleyeceği diğer yöntemlerle iletebilirsiniz.</li>
      </ul>
    </p>
    <h3>Kişisel Verilerin Saklanma Süresi</h3>
    <p>
      Şirket, kişisel verilerin işleme amacının ortadan kalkması, Kanunlar ve ilgili sair mevzuat kapsamında belirlenen
      zorunlu saklama sürelerinin dolmasıyla bilirlikte kişisel verileri siler, yok eder, imha eder veya anonim hale
      getirir.
    </p>
    <h3>Değişiklik ve Güncellemeler</h3>
    <p>
      İş bu aydınlatma metni, 6698 sayılı Kişisel Verilerin Korunması Kanunu ve ilgili sair mevzuat kapsamında
      hazırlanmıştır. İlgili yasal mevzuat ve/veya Şirket’in kişisel veri işleme amaç ve politikalarında meydana gelecek
      değişiklikler doğrultusunda bahse konu aydınlatma metninde gerekli değişiklikler yapılabilir.
    </p>
  </SubPageBase>
);

export default Index;
